var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.showDataDebug)?_c('b-card',{staticClass:"card-custom gutter-b mt-10",attrs:{"body-class":"p-3 text-info"}},[_vm._v(" "+_vm._s(_vm.DashboardSettings)+" ")]):_vm._e(),(_vm.StoredDatas.datas.Dashboard !== undefined)?_c('div',{staticClass:"row d-flex draggable-zone"},_vm._l((typeof _vm.DashboardSettings === 'object'
        ? Object.entries(_vm.DashboardSettings)
            .sort(([, a], [, b]) => a.Order - b.Order)
            .reduce((r, [k, v]) => ({ ...r, [k]: v }), {})
        : {}),function(widget,widgetKey){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(widget.Visible),expression:"widget.Visible"}],key:widgetKey,staticClass:"draggable",class:[
        _vm.getWidgetSize(
          widgetKey,
          _vm.getWidgetExpandable(widgetKey) !== false && typeof widget.Expanded == 'boolean' && widget.Expanded === true ? widget.Expanded : undefined
        ),
        _vm.getWidgetExpandable(widgetKey) !== false ? (typeof widget.Expanded == 'boolean' && widget.Expanded === true ? 'expanded' : 'collapsed') : '',
      ],attrs:{"id":widgetKey}},[_c('keep-alive',[_c(widgetKey,{key:_vm.$language.current,tag:"component",attrs:{"expandable":_vm.getWidgetExpandable(widgetKey),"parameters":widget}})],1)],1)}),0):_c('p',{staticClass:"mb-10"},[_c('translate',[_vm._v("Loading...")])],1),(_vm.showDataDebug)?_c('b-card',{staticClass:"card-custom gutter-b mt-10",attrs:{"body-class":"p-3"}},[_c('pre',[_c('b',[_vm._v("DashboardSettings:")]),_vm._v(" "+_vm._s(_vm.DashboardSettings))]),_c('hr'),_c('pre',[_c('b',[_vm._v("Stored datas:")]),_vm._v(" "+_vm._s(_vm.StoredDatas))])]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }