<template>
  <!-- begin:: Card -->
  <b-card
    :title="NewsWidget.Title"
    :alt="NewsWidget.Title"
    img-top
    :img-src="NewsWidget.ImageSrc"
    text-variant="white"
    header-tag="header"
    header-class="border-0 py-5 position-absolute top-0 bg-transparent w-100 overlay"
    footer-tag="footer"
    footer-class="border-0 py-5"
    class="card-custom bg-primary --bg-radial-gradient-primary gutter-b card-stretch"
  >
    <!-- begin:: Header slot -->
    <template #header>
      <h3 class="card-title font-weight-bolder text-white" v-translate>News</h3>
      <div class="card-toolbar">
        <a
          class="btn btn-transparent btn-hover-info text-hover-white p-2 toggle"
          v-if="expandable"
          @click="
            parameters !== null && typeof parameters.Expanded !== 'undefined' && parameters.Expanded !== null
              ? (parameters.Expanded = !parameters.Expanded)
              : $set(parameters, 'Expanded', false)
          "
          ><span
            class="svg-icon m-0"
            :class="parameters !== null && typeof parameters.Expanded !== 'undefined' && parameters.Expanded ? 'svg-icon-info' : 'svg-icon-opalean-gray-medium'"
            ><inline-svg src="media/svg/icons/Layout/Layout-left-panel-1.svg"></inline-svg
          ></span>
        </a>
        <a class="btn btn-transparent btn-hover-primary text-hover-white p-2 handle"
          ><span class="svg-icon svg-icon-white m-0"><inline-svg src="media/svg/icons/Layout/Layout-4-blocks.svg"></inline-svg></span>
        </a>
      </div>
    </template>
    <!-- end:: Header slot -->

    <!-- begin:: Card body -->
    <b-card-text>
      <div class="d-flex align-items-center mb-8">
        <!-- News symbol -->
        <!-- <div class="symbol symbol-circle">
          <div class="symbol-label" style="background-image: url('/metronic/theme/html/demo1/dist/assets/media/users/blank.png')"></div>
          <i class="symbol-badge symbol-badge-bottom bg-success"></i>
        </div> -->
        <!-- News author -->
        <div class="details --ml-2 mr-auto">
          <span class="d-block font-size-h5 font-weight-bolder">{{ NewsWidget.AuthorName }} </span>
          <span class="d-block font-size-sm font-weight-bold">{{ NewsWidget.AuthorFunction }} </span>
        </div>
        <!-- News date -->
        <div class="details text-right">
          <span class="d-block font-size-h1 font-weight-bolder">{{ NewsWidget.Date | getDateFormat("D") }}</span>
          <span class="d-block font-size-xs font-weight-bolder">{{ NewsWidget.Date | getDateFormat("MMM") }}</span>
        </div>
      </div>
      <b-skeleton-wrapper :loading="typeof NewsWidget.Text === 'undefined' || NewsWidget.length === 0" class="mt-25 w-70">
        <template #loading>
          <b-skeleton animation="fade" width="90%" class="mb-3"></b-skeleton>
          <b-skeleton animation="fade" class="mb-3"></b-skeleton>
          <b-skeleton animation="fade" width="80%" class="mb-3"></b-skeleton>
        </template>
        <span>{{ NewsWidget.Text }}</span>
      </b-skeleton-wrapper>
    </b-card-text>
    <!-- end:: Card body -->

    <!-- begin:: Footer slot -->
    <template #footer>
      <b-button v-for="(Link, index) in NewsWidget.Links" :key="index" variant="success" :href="Link.URL">{{ $gettext(Link.Label) }}</b-button>
    </template>
    <!-- end:: Footer slot -->
  </b-card>
  <!-- end:: Card -->
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "NewsWidget",
  props: {
    expandable: Boolean,
    parameters: Object,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["getDashboardByWidgetName"]),
    NewsWidget: function () {
      return this.getDashboardByWidgetName("NewsWidget")[0]; // Make sure we return a flat array of objects
    },
  },
};
</script>
