<template>
  <div class="card card-custom card-stretch gutter-b">
    <!--begin::Header-->
    <div class="card-header border-0 py-5 bg-opalean-white --border-0 --pt-5">
      <h3 class="card-title">
        <span class="card-label font-weight-bolder font-size-h6 text-info" v-translate>Debts</span>
        <!-- <span class="text-muted mt-3 font-weight-bold font-size-sm" v-translate
          >List ordered by debt</span
        > -->
      </h3>
      <div class="card-toolbar">
        <a
          class="btn btn-transparent btn-hover-info text-hover-white p-2 toggle"
          v-if="expandable"
          @click="
            parameters !== null && typeof parameters.Expanded !== 'undefined' && parameters.Expanded !== null
              ? (parameters.Expanded = !parameters.Expanded)
              : $set(parameters, 'Expanded', false)
          "
          ><span
            class="svg-icon m-0"
            :class="parameters !== null && typeof parameters.Expanded !== 'undefined' && parameters.Expanded ? 'svg-icon-info' : 'svg-icon-opalean-gray-medium'"
            ><inline-svg src="media/svg/icons/Layout/Layout-left-panel-1.svg"></inline-svg
          ></span>
        </a>
        <a class="btn btn-transparent btn-hover-opalean-gray-medium text-hover-white p-2"
          ><span class="svg-icon svg-icon-opalean-gray-medium m-0 handle"><inline-svg src="media/svg/icons/Layout/Layout-4-blocks.svg"></inline-svg></span>
        </a>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body pt-2 pb-0">
      <ul class="nav nav-light-opalean-gray-medium nav-bold nav-pills justify-content-center mt-2 mb-3">
        <li class="nav-item">
          <a class="nav-link" data-toggle="tab" :class="{ active: this.show === 'ByPartner' }" @click="show = 'ByPartner'" v-translate>By partner</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" data-toggle="tab" :class="{ active: this.show === 'ByPallet' }" @click="show = 'ByPallet'" v-translate> By pallet</a>
        </li>
      </ul>

      <b-skeleton-wrapper :loading="!isLoaded">
        <template #loading>
          <b-skeleton-table animation="fade" :rows="5" :columns="2" :table-props="{ bordered: false, striped: false }"></b-skeleton-table>
        </template>
        <!--begin::Table-->
        <b-table
          id="debt-table"
          class="table table-borderless table-vertical-center"
          :items="dataToShow"
          :fields="fields"
          :per-page="perPage"
          :current-page="currentPage"
          borderless
          responsive="sm"
          thead-class="d-none"
          :tbody-tr-class="rowClass"
          v-if="dataToShow.length > 0"
        >
          <!-- A custom formatted column -->
          <template #cell(iconsymbol)="data">
            <router-link
              :to="{
                name: 'route.accounts',
                params: {
                  PartnerName: show === 'ByPartner' ? data.item.label : undefined,
                  PalletName: show === 'ByPallet' ? data.item.label : undefined,
                  GroupBy: show === 'ByPartner' ? 'PartnerID' : 'PalletID',
                },
              }"
              :class="data.item.icon ? 'icon' : 'symbol'"
            >
              <!-- Has icon-->
              <div v-if="data.item.icon">
                <span
                  class="symbol symbol-40 mr-3"
                  v-for="(role, index) in getPalletTypes(data.item.type)"
                  :key="index"
                  :class="[`symbol-light-${role.class}`]"
                  :title="showPopovers ? $gettext('Pallet type') : null"
                  v-b-popover.hover.html="showPopovers ? `<h4 class='font-weight-bolder text-${role.class}'>${role.name}</h4> ${role.description}` : null"
                >
                  <!--begin::Svg Icon -->
                  <span class="symbol-label svg-icon svg-icon-md svg-icon-success">
                    <inline-svg :src="data.item.icon"></inline-svg>
                  </span>
                  <!--end::Svg Icon-->
                </span>
              </div>

              <!-- Has Symbol-->
              <div v-if="data.item.symbol">
                <span
                  v-for="(role, index) in getMainRoleTypes(data.item.mainRole)"
                  :key="index"
                  class="symbol symbol-40 mr-3"
                  :class="[`symbol-light-${role.class}`]"
                  :title="showPopovers ? $gettext('Partner role') : null"
                  v-b-popover.hover.html="showPopovers ? `<h4 class='font-weight-bolder text-${role.class}'>${role.name}</h4> ${role.description}` : null"
                >
                  <span class="symbol-label font-size-h5 font-weight-bold">{{ $mainFunctions.getFirstLetters(data.item.label) }}</span>
                </span>
              </div>
            </router-link>
          </template>

          <!-- Label + Sublable : A custom formatted column -->
          <template #cell(labelsublabel)="data">
            <router-link
              :to="{
                name: 'route.accounts',
                params: {
                  PartnerName: show === 'ByPartner' ? data.item.label : undefined,
                  PalletName: show === 'ByPallet' ? data.item.label : undefined,
                  GroupBy: show === 'ByPartner' ? 'PartnerID' : 'PalletID',
                },
              }"
              class="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg"
            >
              {{ data.item.label }}
            </router-link>
            <span class="text-muted font-weight-bold d-block" v-if="show !== 'ByPallet'">{{ data.item.sublabel }}</span>
          </template>

          <!-- Amount : A formatted column -->
          <template #cell(amount)="data">
            <span class="font-weight-bolder d-block font-size-lg" v-bind:class="data.item.color && `text-${data.item.color}`">{{
              data.value | getNumberFormat
            }}</span>
          </template>
        </b-table>
        <div class="position-absolute top-50 start-0 w-100" v-else>
          <span class="d-flex align-items-center w-50 m-auto"
            ><span class="svg-icon svg-icon-3x mr-4 svg-icon-opalean-gray-light">
              <inline-svg src="media/svg/icons/Code/Stop.svg" />
            </span>
            <span class="text-opalean-gray-light"
              ><strong><translate>There is no data availaible yet</translate></strong
              >, <translate>please come back later...</translate></span
            >
          </span>
        </div>
        <!--end::Table-->
      </b-skeleton-wrapper>
    </div>
    <!--end::Body-->
    <!--end::Footer-->
    <div class="card-footer border-0 py-5 bg-opalean-white --border-0 --pt-5">
      <b-pagination
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        aria-controls="debt-table"
        first-number
        last-number
        class="my-0 pagination-info"
      ></b-pagination>
    </div>
    <!--end::Footer-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import statics from "@/core/statics/statics.js";
import helpers from "@/core/statics/helpers.js";

export default {
  name: "DebtsWidget",
  props: {
    expandable: Boolean,
    parameters: Object,
  },
  data() {
    return {
      // Preferences
      showPopovers: statics.showPopovers,
      //
      show: "ByPartner",
      perPage: 5,
      currentPage: 1,
      intervalCount: 0,
      MainRoles: statics.partnerMainRoleTypes,
      palletTypes: statics.palletTypes,
      fields: [
        {
          key: "iconsymbol",
          tdClass: "text-left pl-0 w-10",
        },
        "labelsublabel",
        {
          key: "amount",
          sortable: true,
          tdClass: "text-right pr-0",
        },
      ],
      isLoaded: false,
    };
  },
  watch: {
    DebtsWidget: {
      immediate: true, // Initiate at first load, trigger the callback immediately with the current value of the expression
      handler: function () {
        if (this.DebtsWidget[0].WidgetName === "DebtsWidget") this.isLoaded = true;
      },
    },
  },
  mixins: [helpers],
  methods: {
    rowClass(item, type) {
      if (!item || type !== "row") return;
      if (item.status === "highlighted") return "table-success";
    },
    toggleShow() {
      if (this.show === "ByPallet") this.show = "ByPartner";
      else if (this.show === "ByPartner") this.show = "ByPallet";
    },
    // Static helpers methods
    // Used when v-for and v-if needed : v-if="item.MainRole == role.id"
    // ** See helpers.js
  },
  computed: {
    ...mapGetters(["getDashboardByWidgetName", "getPallet", "getPartnerByPartnerID"]),
    DebtsWidget: function () {
      return this.getDashboardByWidgetName("DebtsWidget");
    },
    ByPallet: function () {
      let _byPallet = [];
      // console.log("typeof this.DebtsWidget", typeof this.DebtsWidget);
      // console.log(
      //   "typeof this.DebtsWidget[0].Parameters",
      //   typeof this.DebtsWidget[0].Parameters
      // );
      if ((typeof this.DebtsWidget !== "undefined") & (typeof this.DebtsWidget[0].Parameters !== "undefined")) {
        let Pallets = this.DebtsWidget.find((c) => c.Parameters == "Pallets");
        Pallets.Data.forEach((o) => {
          let Pallet = this.getPallet(o.ObjectID);
          if (typeof Pallet === "object") {
            _byPallet.push({
              id: Pallet.PalletID,
              type: Pallet.PalletType,
              icon: this.getPalletType(Pallet.PalletType, "icon"),
              class: this.getPalletType(Pallet.PalletType, "class"),
              symbol: null,
              label: Pallet.PalletName,
              sublabel: this.getPalletType(Pallet.PalletType, "name"),
              amount: o.Quantity,
              // color: o.Quantity > 200 ? "danger" : o.Quantity > 100 ? "warning" : "success", // To be stored in User Settings
              color: o.Quantity < 0 ? "info" : "opalean-info",
              // permalink: "/Pallet/" + Pallet.PalletID,
              //status: Pallet.ByDefault === true ? "highlighted" : ""
            });
          }
        });
        return _byPallet;
      } else return []; // Fix avoid error when AlertsWidget is loading
    },
    ByPartner: function () {
      let _byPartner = [];
      // console.log("typeof this.DebtsWidget", typeof this.DebtsWidget);
      // console.log(
      //   "typeof this.DebtsWidget[0].Parameters",
      //   typeof this.DebtsWidget[0].Parameters
      // );
      if ((typeof this.DebtsWidget !== "undefined") & (typeof this.DebtsWidget[0].Parameters !== "undefined")) {
        let Partners = this.DebtsWidget.find((c) => c.Parameters == "Partners");
        Partners.Data.forEach((o) => {
          let Partner = this.getPartnerByPartnerID(o.ObjectID);
          if (typeof Partner === "object") {
            _byPartner.push({
              id: Partner.PartnerID,
              mainRole: Partner.MainRole,
              //icon: this.getPartnerMainRoleTypes(Partner.MainRole, "icon"),
              class: this.getMainRoleType(Partner.MainRole, "class"),
              symbol: this.$mainFunctions.getFirstLetters(Partner.Name),
              label: Partner.Name,
              sublabel: Partner.City,
              amount: o.Quantity,
              //color: "warning",
              //color: this.getPartnerMainRoleTypes(Partner.MainRole, "class"),
              // color: o.Quantity > 20000 ? "danger" : o.Quantity > 10000 ? "warning" : "success",  // To be stored in User Settings
              color: o.Quantity < 0 ? "info" : "opalean-info",
              // permalink: "/Partner/" + Partner.PartnerID,
              //status: Partner.Active === true ? "highlighted" : "",
            });
          }
        });
        return _byPartner;
      } else return []; // Fix avoid error when AlertsWidget is loading
    },
    rows() {
      if (this.show === "ByPallet") return this.ByPallet.length;
      if (this.show === "ByPartner") return this.ByPartner.length;
      return this.ByPallet.length;
    },
    dataToShow() {
      if (this.show === "ByPallet") return this.ByPallet;
      if (this.show === "ByPartner") return this.ByPartner;
      return this.ByPallet;
    },
  },
  mounted() {
    // Set Pallets
    /*let Pallets = this.DebtsWidget.find(c => c.Parameters == "Pallets");
    Pallets.Data.forEach(o => {
      let Pallet = this.getPallet(o.ObjectID);
      if (typeof Pallet === "object") {
        this.ByPallet.push({
          id: Pallet.PalletID,
          type: Pallet.PalletType,
          icon: this.getPalletType(Pallet.PalletType, "icon"),
          class: this.getPalletType(Pallet.PalletType, "class"),
          symbol: null,
          label: Pallet.PalletName,
          sublabel: this.getPalletType(Pallet.PalletType, "name"),
          amount: o.Quantity,
          color:
            o.Quantity > 200
              ? "danger"
              : o.Quantity > 100
              ? "warning"
              : "success",
          permalink: "/Pallet/" + Pallet.PalletID
          //status: Pallet.ByDefault === true ? "highlighted" : ""
        });
      }
    }); */
    // Fix > Better with a filter AND need to be a computed fct
    // Set Partners
    /*let Partners = this.DebtsWidget.find(c => c.Parameters == "Partners");
    Partners.Data.forEach(o => {
      let Partner = this.getPartnerByGlobalID(o.ObjectID);
      if (typeof Partner === "object") {
        this.ByPartner.push({
          id: Partner.GlobalID,
          mainRole: Partner.MainRole,
          //icon: this.getPartnerMainRoleTypes(Partner.MainRole, "icon"),
          class: this.getMainRoleType(Partner.MainRole, "class"),
          symbol: this.$mainFunctions.getFirstLetters(Partner.Name),
          label: Partner.Name,
          sublabel: Partner.City,
          amount: o.Quantity,
          //color: "warning",
          //color: this.getPartnerMainRoleTypes(Partner.MainRole, "class"),
          color:
            o.Quantity > 20000
              ? "danger"
              : o.Quantity > 10000
              ? "warning"
              : "success",
          permalink: "/Partner/" + Partner.GlobalID
          //status: Partner.Active === true ? "highlighted" : "",
        });
      }
    });*/
    // Fix > Better with a filter AND need to be a computed fct
    // Set interval
    // this.interval = setInterval(() => {
    //   this.toggleShow();
    //   this.intervalCount++;
    // }, 15000);
  },
};
</script>
