var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card card-custom gutter-b card-stretch"},[_c('div',{staticClass:"card-header border-0 py-5 bg-opalean-white"},[_c('h3',{staticClass:"card-title align-items-start flex-column my-1"},[_c('span',{directives:[{name:"translate",rawName:"v-translate"}],staticClass:"card-label font-weight-bolder text-dark"},[_vm._v(" Activity ")]),_c('span',{directives:[{name:"translate",rawName:"v-translate"}],staticClass:"text-muted mt-2 font-weight-bold font-size-sm"},[_vm._v(" Operations ")])]),_c('div',{staticClass:"card-toolbar"},[(_vm.expandable)?_c('a',{staticClass:"btn btn-transparent btn-hover-info text-hover-white p-2 toggle",on:{"click":function($event){_vm.parameters !== null && typeof _vm.parameters.Expanded !== 'undefined' && _vm.parameters.Expanded !== null
            ? (_vm.parameters.Expanded = !_vm.parameters.Expanded)
            : _vm.$set(_vm.parameters, 'Expanded', false)}}},[_c('span',{staticClass:"svg-icon m-0",class:_vm.parameters !== null && typeof _vm.parameters.Expanded !== 'undefined' && _vm.parameters.Expanded ? 'svg-icon-info' : 'svg-icon-opalean-gray-medium'},[_c('inline-svg',{attrs:{"src":"media/svg/icons/Layout/Layout-left-panel-1.svg"}})],1)]):_vm._e(),_c('a',{staticClass:"btn btn-transparent btn-hover-info text-hover-white p-2 handle"},[_c('span',{staticClass:"svg-icon svg-icon-opalean-gray-medium m-0"},[_c('inline-svg',{attrs:{"src":"media/svg/icons/Layout/Layout-4-blocks.svg"}})],1)])])]),_c('div',{staticClass:"card-body"},[_c('b-skeleton-wrapper',{attrs:{"loading":!_vm.ActivityWidget.Data},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('div',{staticClass:"row"},[_c('b-col',{attrs:{"cols":"4"}},[_c('b-card',{attrs:{"no-body":"","img-top":""}},[_c('b-skeleton-img',{attrs:{"animation":"fade","card-img":"top","aspect":"4:3"}})],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-card',{attrs:{"no-body":"","img-top":""}},[_c('b-skeleton-img',{attrs:{"animation":"fade","card-img":"top","aspect":"4:3"}})],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-card',{attrs:{"no-body":"","img-top":""}},[_c('b-skeleton-img',{attrs:{"animation":"fade","card-img":"top","aspect":"4:3"}})],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-card',{attrs:{"no-body":"","img-top":""}},[_c('b-skeleton-img',{attrs:{"animation":"fade","card-img":"top","aspect":"4:3"}})],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-card',{attrs:{"no-body":"","img-top":""}},[_c('b-skeleton-img',{attrs:{"animation":"fade","card-img":"top","aspect":"4:3"}})],1)],1)],1)]},proxy:true}])},[_c('div',{staticClass:"row"},[_vm._l((_vm.ActivityWidget.Data),function(item,i){return [_c('div',{key:i,class:_vm.ActivityWidget.Data.length > 4 ? 'col-6 col-sm-4' : 'col-6 col-sm-6'},[_c('div',{staticClass:"card card-custom gutter-b mb-0",class:_vm.getStatusType(item.Status, 'plain')
                  ? `bg-${_vm.getStatusType(item.Status, 'class')}`
                  : `bg-opalean-white bg-hover-${_vm.getStatusType(item.Status, 'class')} text-hover-light`,staticStyle:{"height":"160px"}},[_c('div',{staticClass:"card-body d-flex flex-column justify-content-center hoverable"},[_c('div',{staticClass:"text-hover-light"},[_c('span',{staticClass:"svg-icon svg-icon-3x ml-n2",class:_vm.getStatusType(item.Status, 'plain') ? 'svg-icon-white' : `svg-icon-${_vm.getStatusType(item.Status, 'class')}`},[_c('inline-svg',{attrs:{"src":`${_vm.getStatusType(item.Status, 'icon')}`}})],1),_c('span',{staticClass:"font-weight-bold --font-size-h1 display4 mt-3 text-primary text-hover-light align-middle",class:_vm.getStatusType(item.Status, 'plain')
                        ? `text-inverse-${_vm.getStatusType(item.Status, 'class')}`
                        : `text-${_vm.getStatusType(item.Status, 'class')} text-hover-light`},[(item.Status !== 'A')?_c('span',[_vm._v(" "+_vm._s(_vm._f("getNumberFormat")(item.Value))+" ")]):_c('span',[_vm._v(" "+_vm._s(item.Value)+" ")])])]),(item.Status !== 'A')?_c('router-link',{staticClass:"stretched-link font-weight-bold font-size-lg mt-2 d-block",class:_vm.getStatusType(item.Status, 'plain') ? `text-inverse-${_vm.getStatusType(item.Status, 'class')}` : 'text-muted text-hover-light',attrs:{"custom":"","to":{ name: 'route.operations.list', params: { Status: item.Status, runCallBySearch: true } }}},[_vm._v(_vm._s(_vm.$gettext(_vm.getStatusType(item.Status, "label")))+" ")]):_c('div',{staticClass:"stretched-link font-weight-bold font-size-lg mt-2 text-muted text-hover-light",on:{"click":_vm.jumpToAlerts}},[_vm._v(" "+_vm._s(_vm.$gettext(_vm.getStatusType(item.Status, "label")))+" ")])],1)])])]})],2)])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }