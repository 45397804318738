var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card card-custom card-stretch gutter-b"},[_c('div',{staticClass:"card-header border-0 py-5 bg-opalean-white --border-0 --pt-5"},[_c('h3',{staticClass:"card-title"},[_c('span',{directives:[{name:"translate",rawName:"v-translate"}],staticClass:"card-label font-weight-bolder font-size-h6 text-opalean-gray-medium"},[_vm._v("Totals")])]),_c('div',{staticClass:"card-toolbar"},[(_vm.expandable)?_c('a',{staticClass:"btn btn-transparent btn-hover-info text-hover-white p-2 toggle",on:{"click":function($event){_vm.parameters !== null && typeof _vm.parameters.Expanded !== 'undefined' && _vm.parameters.Expanded !== null
            ? (_vm.parameters.Expanded = !_vm.parameters.Expanded)
            : _vm.$set(_vm.parameters, 'Expanded', false)}}},[_c('span',{staticClass:"svg-icon m-0",class:_vm.parameters !== null && typeof _vm.parameters.Expanded !== 'undefined' && _vm.parameters.Expanded ? 'svg-icon-info' : 'svg-icon-opalean-gray-medium'},[_c('inline-svg',{attrs:{"src":"media/svg/icons/Layout/Layout-left-panel-1.svg"}})],1)]):_vm._e(),_c('a',{staticClass:"btn btn-transparent btn-hover-opalean-gray-medium text-hover-white p-2 handle"},[_c('span',{staticClass:"svg-icon svg-icon-opalean-gray-medium m-0 handle"},[_c('inline-svg',{attrs:{"src":"media/svg/icons/Layout/Layout-4-blocks.svg"}})],1)])])]),_c('div',{staticClass:"card-body pt-2 pb-0"},[_c('ul',{staticClass:"nav nav-light-opalean-gray-medium nav-bold nav-pills justify-content-center mt-2 mb-3"},[_c('li',{staticClass:"nav-item"}),_c('li',{staticClass:"nav-item"},[_c('a',{directives:[{name:"translate",rawName:"v-translate"}],staticClass:"nav-link",class:{ active: this.show === 'ByPallet' },attrs:{"data-toggle":"tab"},on:{"click":function($event){_vm.show = 'ByPallet'}}},[_vm._v(" By pallet")])])]),_c('b-skeleton-wrapper',{attrs:{"loading":!_vm.isLoaded},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('b-skeleton-table',{attrs:{"animation":"fade","rows":5,"columns":2,"table-props":{ bordered: false, striped: false }}})]},proxy:true}])},[(_vm.dataToShow.length > 0)?_c('b-table',{staticClass:"table table-borderless table-vertical-center",attrs:{"id":"credit-table","items":_vm.dataToShow,"fields":_vm.fields,"per-page":_vm.perPage,"current-page":_vm.currentPage,"borderless":"","responsive":"sm","thead-class":"d-none","tbody-tr-class":_vm.rowClass},scopedSlots:_vm._u([{key:"cell(iconsymbol)",fn:function(data){return [_c('router-link',{class:data.item.icon ? 'icon' : 'symbol',attrs:{"to":{
              name: 'route.accounts',
              params: {
                PartnerName: _vm.show === 'ByPartner' ? data.item.label : undefined,
                PalletName: _vm.show === 'ByPallet' ? data.item.label : undefined,
                GroupBy: _vm.show === 'ByPartner' ? 'PartnerID' : 'PalletID',
              },
            }}},[(data.item.icon)?_c('div',_vm._l((_vm.getPalletTypes(data.item.type)),function(role,index){return _c('span',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.html",value:(_vm.showPopovers ? `<h4 class='font-weight-bolder text-${role.class}'>${role.name}</h4> ${role.description}` : null),expression:"showPopovers ? `<h4 class='font-weight-bolder text-${role.class}'>${role.name}</h4> ${role.description}` : null",modifiers:{"hover":true,"html":true}}],key:index,staticClass:"symbol symbol-40 mr-3",class:[`symbol-light-${role.class}`],attrs:{"title":_vm.showPopovers ? _vm.$gettext('Pallet type') : null}},[_c('span',{staticClass:"symbol-label svg-icon svg-icon-md svg-icon-success"},[_c('inline-svg',{attrs:{"src":data.item.icon}})],1)])}),0):_vm._e(),(data.item.symbol)?_c('div',_vm._l((_vm.getMainRoleTypes(data.item.mainRole)),function(role,index){return _c('span',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.html",value:(_vm.showPopovers ? `<h4 class='font-weight-bolder text-${role.class}'>${role.name}</h4> ${role.description}` : null),expression:"showPopovers ? `<h4 class='font-weight-bolder text-${role.class}'>${role.name}</h4> ${role.description}` : null",modifiers:{"hover":true,"html":true}}],key:index,staticClass:"symbol symbol-40 mr-3",class:[`symbol-light-${role.class}`],attrs:{"title":_vm.showPopovers ? _vm.$gettext('Partner role') : null}},[_c('span',{staticClass:"symbol-label font-size-h5 font-weight-bold"},[_vm._v(_vm._s(_vm.$mainFunctions.getFirstLetters(data.item.label)))])])}),0):_vm._e()])]}},{key:"cell(labelsublabel)",fn:function(data){return [_c('router-link',{staticClass:"text-dark font-weight-bold text-hover-opalean-info mb-1 font-size-lg",attrs:{"to":{
              name: 'route.accounts',
              params: {
                PartnerName: _vm.show === 'ByPartner' ? data.item.label : undefined,
                PalletName: _vm.show === 'ByPallet' ? data.item.label : undefined,
                GroupBy: _vm.show === 'ByPartner' ? 'PartnerID' : 'PalletID',
              },
            }}},[_vm._v(" "+_vm._s(data.item.label)+" ")]),(_vm.show !== 'ByPallet')?_c('span',{staticClass:"text-muted font-weight-bold d-block"},[_vm._v(_vm._s(data.item.sublabel))]):_vm._e()]}},{key:"cell(amount)",fn:function(data){return [_c('span',{staticClass:"font-weight-bolder d-block font-size-lg",class:data.item.color && `text-${data.item.color}`},[_vm._v(_vm._s(_vm._f("getNumberFormat")(data.value)))])]}}],null,false,4178496149)}):_c('div',{staticClass:"position-absolute top-50 start-0 w-100"},[_c('span',{staticClass:"d-flex align-items-center w-50 m-auto"},[_c('span',{staticClass:"svg-icon svg-icon-3x mr-4 svg-icon-opalean-gray-light"},[_c('inline-svg',{attrs:{"src":"media/svg/icons/Code/Stop.svg"}})],1),_c('span',{staticClass:"text-opalean-gray-light"},[_c('strong',[_c('translate',[_vm._v("There is no data availaible yet")])],1),_vm._v(", "),_c('translate',[_vm._v("please come back later...")])],1)])])],1)],1),_c('div',{staticClass:"card-footer border-0 py-5 bg-opalean-white --border-0 --pt-5"},[_c('b-pagination',{staticClass:"my-0 pagination-opalean-gray-medium",attrs:{"total-rows":_vm.rows,"per-page":_vm.perPage,"aria-controls":"credit-table","first-number":"","last-number":""},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }