<template>
  <div class="card card-custom gutter-b card-stretch">
    <!--begin::Header-->
    <div class="card-header border-0 py-5 bg-warning --border-0 --pt-5">
      <h3 class="card-title">
        <span class="svg-icon svg-icon-2x ml-n2 mr-2 svg-icon-white">
          <inline-svg src="media/svg/icons/Electric/Highvoltage.svg" />
        </span>

        <span class="card-label font-weight-bolder font-size-h6 text-white" v-translate> Alerts </span>
        <!-- <span class="text-muted mt-3 font-weight-bold font-size-sm" v-translate
          >Alerts</span
        > -->
      </h3>
      <div class="card-toolbar">
        <a
          class="btn btn-transparent btn-hover-info text-hover-white p-2 toggle"
          v-if="expandable"
          @click="
            parameters !== null && typeof parameters.Expanded !== 'undefined' && parameters.Expanded !== null
              ? (parameters.Expanded = !parameters.Expanded)
              : $set(parameters, 'Expanded', false)
          "
          ><span
            class="svg-icon m-0"
            :class="parameters !== null && typeof parameters.Expanded !== 'undefined' && parameters.Expanded ? 'svg-icon-info' : 'svg-icon-opalean-gray-medium'"
            ><inline-svg src="media/svg/icons/Layout/Layout-left-panel-1.svg"></inline-svg
          ></span>
        </a>
        <a class="btn btn-transparent btn-hover-light text-hover-white p-2 handle"
          ><span class="svg-icon svg-icon-white m-0"><inline-svg src="media/svg/icons/Layout/Layout-4-blocks.svg"></inline-svg></span>
        </a>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body pt-2 pb-0">
      <ul class="nav nav-warning nav-bold nav-pills justify-content-center mt-2 mb-3">
        <li class="nav-item">
          <a class="nav-link" data-toggle="tab" :class="{ active: show === 'AlertsIn' }" @click="show = 'AlertsIn'" v-translate>Alert in</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" data-toggle="tab" :class="{ active: show === 'AlertsOut' }" @click="show = 'AlertsOut'" v-translate>Alert out</a>
        </li>
      </ul>

      <b-skeleton-wrapper :loading="!isLoaded">
        <template #loading>
          <b-skeleton-table animation="fade" :rows="5" :columns="5" :table-props="{ bordered: false, striped: false }"></b-skeleton-table>
        </template>
        <!--begin::Table :tbody-tr-class="rowClass" removed-->
        <b-table
          id="debt-table"
          class="table table-borderless table-vertical-center"
          :class="show"
          :items="dataToShow"
          :fields="fields"
          :per-page="perPage"
          :current-page="currentPage"
          borderless
          responsive="sm"
          v-if="dataToShow.length > 0"
          :sort-compare="sortDate"
        >
          <template #cell(PalletID)="data">
            <router-link
              :to="`/partners/edit/${data.item.PartnerID}`"
              class="text-warning font-weight-bold text-hover-primary mb-1 font-size-lg"
              v-if="data.item.PartnerID !== null && data.item.PartnerID !== 0 && showDataDebug === true"
            >
              {{ getPartnerByPartnerID(data.item.PartnerID) | getSafeObjectValue("Name") }}
            </router-link>
            <span class="text-warning font-weight-bold mb-1 font-size-lg" v-else>{{
              getPartnerByPartnerID(data.item.PartnerID) | getSafeObjectValue("Name")
            }}</span>

            <span class="text-muted font-weight-bold d-block">{{ getPallet(data.item.PalletID) | getSafeObjectValue("PalletName") }}</span>
          </template>

          <template #cell(labelCreationDate)="data">
            <span class="text-muted font-weight-bold d-block">{{ Date.parse(data.item.AlertDate) | getDateFormat("LocaleDate") }}</span>
          </template>
          <!-- Label + Sublable : A custom formatted column -->
          <template #cell(labelNotificationDate)="data">
            <span v-if="show === 'AlertsIn'" class="text-muted font-weight-bold d-block">{{
              Date.parse(data.item.NotificationDate) | getDateFormat("LocaleDate")
            }}</span>
            <span v-else class="text-muted font-weight-bold d-block">—</span>
          </template>

          <!-- Amount : A formatted column -->
          <template #cell(Quantity)="data">
            <span class="font-weight-bold d-block font-size-h3" v-bind:class="show === 'AlertsIn' ? 'text-opalean-info' : 'text-info'">{{
              data.value | getNumberFormat
            }}</span>
          </template>

          <!-- Limit : A formatted column -->
          <template #cell(MaxAllowed)="data">
            <span class="font-weight-medium d-block font-size-h3">{{ show === "AlertsOut" ? "-" : "" }}{{ data.value }}</span>
          </template>

          <!-- Limit : A formatted column -->
          <template #cell(action)="data">
            <a
              v-if="data.item.Direction == 'IN' && getUserEmails.length > 0 && getUserEmails[0] !== ''"
              :href="data.item.emails"
              class="btn btn-icon btn-light-opalean-gray-light btn-sm"
              @click="callChatModal(data)"
            >
              <span class="svg-icon svg-icon-md svg-icon-dark">
                <!--begin::Svg Icon -->
                <inline-svg src="media/svg/icons/Communication/Mail-error.svg"></inline-svg>
                <!--end::Svg Icon-->
              </span>
            </a>
            <span v-else class="text-muted font-weight-bold d-block">—</span>
          </template>
        </b-table>
        <div class="position-absolute top-50 start-0 w-100" v-else>
          <span class="d-flex align-items-center w-50 m-auto"
            ><span class="svg-icon svg-icon-3x mr-4 svg-icon-opalean-gray-light">
              <inline-svg src="media/svg/icons/Code/Stop.svg" />
            </span>
            <span class="text-opalean-gray-light"
              ><strong><translate>There is no alerts triggered yet</translate></strong
              >, <translate>create alerts for a partner or wait an alert to trigger...</translate></span
            >
          </span>
        </div>
        <!--end::Table-->
      </b-skeleton-wrapper>
    </div>
    <!--end::Body-->
    <!--end::Footer-->
    <div class="card-footer border-0 py-5 bg-opalean-white --border-0 --pt-5">
      <b-pagination
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        aria-controls="alerts-table"
        first-number
        last-number
        class="my-0 pagination-secondary TODO"
      ></b-pagination>
    </div>
    <!--end::Footer-->

    <!--begin::ChatModal -->
    <ChatModal :chatModal.sync="chatModal" v-if="chatModal.show"></ChatModal>
    <!--end::ChatModal -->
  </div>
</template>

<style lang="scss">
.AlertsOut .labelToHide {
  display: none;
}
</style>

<script>
import { mapGetters } from "vuex";
import ChatModal from "@/view/content/neoopatrace/components/ChatModal.vue";
import statics from "@/core/statics/statics.js";

import * as moment from "moment";

import Vue from "vue";
var vm = new Vue();

//import ApiService from "@/core/services/api.service";

export default {
  name: "AlertsWidget",
  components: {
    ChatModal,
  },
  props: {
    expandable: Boolean,
    parameters: Object,
  },
  data() {
    return {
      // Preferences
      showDataDebug: statics.showDataDebug,

      show: "AlertsIn",
      chatModal: {
        index: null,
        show: false,
        title: "",
        UserName: null,
        UserPartnerName: null,
        PartnerName: null,
        PalletName: null,
        Amount: null,
      },
      perPage: 5,
      currentPage: 1,
      intervalCount: 0,
      fields: [
        {
          key: "PalletID",
          label: vm.$gettext("Thirdparty"),
          tdClass: "text-left pl-0",
          thClass: "",
        },
        {
          key: "labelCreationDate",
          label: vm.$gettext("Alert date"),
          sortable: true,
          tdClass: "text-center",
          thClass: "w-120px",
        },
        {
          key: "labelNotificationDate",
          label: vm.$gettext("Notification Date"),
          sortable: true,
          tdClass: "text-center labelToHide",
          thClass: "w-120px labelToHide",
        },
        {
          key: "Quantity",
          label: vm.$gettext("Amount"),
          sortable: true,
          tdClass: "text-center",
          thClass: "w-120px",
        },
        {
          key: "MaxAllowed",
          label: vm.$gettext("Limit"),
          sortable: true,
          tdClass: "text-center",
          thClass: "w-120px",
        },
        {
          key: "action",
          label: "",
          tdClass: "text-right pr-0 labelToHide",
          thClass: "w-60px labelToHide",
        },
      ],
      // AlertsIn: [],
      // AlertsOut: []
      isLoaded: false,
    };
  },
  computed: {
    ...mapGetters(["getPallet", "getPartnerByPartnerID", "getDashboardByWidgetName", "getUserName", "getUserPartnerName", "getUserEmails"]),
    AlertsWidget: function () {
      return this.getDashboardByWidgetName("AlertsWidget")[0];
    },
    AlertsIn: function () {
      // console.log(
      //   "typeof this.AlertsWidget.Alerts",
      //   typeof this.AlertsWidget.Alerts
      // );
      if (typeof this.AlertsWidget.Alerts !== "undefined") return this.AlertsWidget.Alerts.filter((a) => a.Direction == "IN");
      else return []; // Fix avoid error when AlertsWidget is loading
    },
    AlertsOut: function () {
      // console.log(
      //   "typeof this.AlertsWidget.Alerts",
      //   typeof this.AlertsWidget.Alerts
      // );
      if (typeof this.AlertsWidget.Alerts !== "undefined") return this.AlertsWidget.Alerts.filter((a) => a.Direction == "OUT");
      else return []; // Fix avoid error when AlertsWidget is loading
    },
    rows() {
      if (this.show === "AlertsIn") return this.AlertsIn.length;
      if (this.show === "AlertsOut") return this.AlertsOut.length;
      return this.AlertsIn.length;
    },
    dataToShow() {
      if (this.show === "AlertsIn") return this.AlertsIn;
      if (this.show === "AlertsOut") return this.AlertsOut;
      return this.AlertsIn;
    },
  },
  watch: {
    AlertsWidget: {
      immediate: true, // Initiate at first load, trigger the callback immediately with the current value of the expression
      handler: function () {
        if (this.AlertsWidget.WidgetName === "AlertsWidget") this.isLoaded = true;
      },
    },
  },
  methods: {
    toggleShow() {
      if (this.show === "AlertsIn") this.show = "AlertsOut";
      else if (this.show === "AlertsOut") this.show = "AlertsIn";
    },
    // Modal
    callChatModal(data) {
      //console.log(data);

      this.chatModal.index = data.index;
      this.chatModal.title = this.getPartnerByPartnerID(data.item.PartnerID) !== undefined ? this.getPartnerByPartnerID(data.item.PartnerID).Name : "—";
      this.chatModal.UserName = this.getUserName;
      this.chatModal.UserPartnerName = this.getUserPartnerName;
      this.chatModal.PartnerName = this.getPartnerByPartnerID(data.item.PartnerID) !== undefined ? this.getPartnerByPartnerID(data.item.PartnerID).Name : "—";
      this.chatModal.PalletName = this.getPallet(data.item.PalletID) !== undefined ? this.getPallet(data.item.PalletID).PalletName : "—";
      this.chatModal.Quantity = data.item.Quantity;
      this.chatModal.AlertEMails = data.item.AlertEMails;

      this.chatModal.PartnerID = data.item.PartnerID;
      this.chatModal.PalletID = data.item.PalletID;
      this.chatModal.Direction = data.item.Direction;

      if (this.chatModal.show === false) this.chatModal.show = true;
    },
    // Sorting
    // https://stackoverflow.com/questions/56427185/bootstrapvue-table-sort-by-date-and-by-string
    sortDate(a, b, key) {
      console.log("sortDate::", a, b, key);
      if (!(key === "labelCreationDate" || key === "labelNotificationDate")) {
        // `key` is not the field that is a date.
        // Let b-table handle the sorting for other columns
        // returning null or false will tell b-table to fall back to it's
        // internal sort compare routine for fields keys other than `myDateField`
        return null; // or false
      }
      let k = key === "labelCreationDate" ? "AlertDate" : "NotificationDate";
      if (moment(a[k]).isValid && moment(b[k]).isValid) {
        if (moment(a[k]).isBefore(b[k])) {
          return -1;
        } else if (moment(a[k]).isAfter(b[k])) {
          return 1;
        } else {
          return 0;
        }
      }
      return null;
    },
  },
  mounted() {
    // Set Alerts In / Out
    // this.AlertsWidget[0].Alerts.forEach(a => {
    //   if (a.Direction == "IN") this.AlertsIn.push(a);
    //   else if (a.Direction == "OUT") this.AlertsOut.push(a);
    // }); // Fix > Better with a filter AND need to be a computed fct
    // Set interval
    // this.interval = setInterval(() => {
    //   this.toggleShow();
    //   this.intervalCount++;
    // }, 15000);
  },
};
</script>
