<template>
  <!-- begin:: Card -->
  <b-card
    text-variant="white"
    header-tag="header"
    header-class="border-0 py-5"
    footer-tag="footer"
    footer-class="border-0 py-5"
    bg-variant="warning"
    class="card-custom bg-warning gutter-b card-stretch"
  >
    <!-- begin:: Header slot -->
    <template #header>
      <h3 class="card-title font-weight-bolder text-white" v-translate>Contact</h3>
      <div class="card-toolbar">
        <a
          class="btn btn-transparent btn-hover-info text-hover-white p-2 toggle"
          v-if="expandable"
          @click="
            parameters !== null && typeof parameters.Expanded !== 'undefined' && parameters.Expanded !== null
              ? (parameters.Expanded = !parameters.Expanded)
              : $set(parameters, 'Expanded', false)
          "
          ><span
            class="svg-icon m-0"
            :class="parameters !== null && typeof parameters.Expanded !== 'undefined' && parameters.Expanded ? 'svg-icon-info' : 'svg-icon-opalean-gray-medium'"
            ><inline-svg src="media/svg/icons/Layout/Layout-left-panel-1.svg"></inline-svg
          ></span>
        </a>
        <a class="btn btn-transparent btn-hover-light text-hover-white p-2 handle"
          ><span class="svg-icon svg-icon-white m-0"><inline-svg src="media/svg/icons/Layout/Layout-4-blocks.svg"></inline-svg></span>
        </a>
      </div>
    </template>
    <!-- end:: Header slot -->

    <!-- begin:: Card body -->
    <b-card-text class="d-flex flex-column align-items-center justify-content-center h-100">
      <span class="svg-icon svg-icon-9x mb-4 svg-icon-white">
        <inline-svg src="media/svg/icons/Communication/Urgent-mail.svg" />
      </span>
      <b-skeleton-wrapper :loading="typeof ContactWidget.Title === 'undefined' || ContactWidget.Title === undefined" class="w-70">
        <template #loading>
          <b-skeleton animation="fade"></b-skeleton>
          <b-skeleton animation="fade"></b-skeleton>
        </template>
        <span class="font-size-h6 text-center font-weight-bold">
          {{ ContactWidget.Title }}
        </span>
        <span class="font-size-md text-center mt-6">
          {{ ContactWidget.Text }}
        </span>
      </b-skeleton-wrapper>
    </b-card-text>
    <!-- end:: Card body -->

    <!-- begin:: Footer slot -->
    <template #footer>
      <b-button-group>
        <b-button v-for="(Link, index) in ContactWidget.Links" :key="index" variant="light-warning" :href="Link.URL">{{ $gettext(Link.Label) }}</b-button>
      </b-button-group>
    </template>
    <!-- end:: Footer slot -->
  </b-card>
  <!-- end:: Card -->
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ContactWidget",
  props: {
    expandable: Boolean,
    parameters: Object,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["getDashboardByWidgetName"]),
    ContactWidget: function () {
      return this.getDashboardByWidgetName("ContactWidget")[0]; // Make sure we return a flat array of objects
    },
  },
};
</script>
