<template>
  <!-- begin::ChatModal -->
  <div
    class="modal modal-sticky modal-sticky-bottom-right show"
    id="kt_chat_modal"
    role="dialog"
    data-backdrop="false"
    aria-modal="true"
    style="display: block; padding-left: 0px"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <!--begin::Card-->
        <div class="card card-custom">
          <!--begin::Header-->
          <div class="card-header align-items-center px-4 py-3 bg-warning text-white">
            <div class="text-left flex-grow-1">
              <!--begin::Dropdown Menu-->
              <span class="svg-icon svg-icon-2x --ml-n2 mr-2 svg-icon-white">
                <inline-svg src="media/svg/icons/Electric/Highvoltage.svg" />
              </span>
              <!--end::Dropdown Menu-->
            </div>
            <div class="text-center flex-grow-1">
              <div class="card-label font-weight-bolder font-size-h5 text-white" v-translate>Send alerts</div>
              <div class="card-label font-weight-bold font-size-h6 text-white">{{ chatModal.title }}</div>
            </div>
            <div class="text-right flex-grow-1">
              <button type="button" class="btn btn-outline-white btn-sm btn-icon btn-icon-md" @click="dismissModal()">
                <i class="ki ki-close icon-1x"></i>
              </button>
            </div>
          </div>
          <!--end::Header-->

          <!--begin::Body-->

          <div class="card-body align-items-center border-bottom">
            <div class="form-group">
              <label class="font-weight-bold" for="alert-item-email"><translate>Email(s)</translate> <span class="required">*</span></label>
              <Multiselect
                id="alert-item-email"
                v-model="chatForm.Emails"
                :tag-placeholder="$gettext('Add this as new email')"
                :placeholder="$gettext('Search or add an email')"
                :options="accountEmails"
                :multiple="true"
                :taggable="true"
                @tag="(searchQuery, id) => addEmail(searchQuery)"
              ></Multiselect>
              <b-form-text id="alert-item-email-help" v-translate
                >This list of emails will receive your defined alert. Fill one or multiple emails.</b-form-text
              >
            </div>

            <div class="form-group mb-0">
              <label class="font-weight-bold" for="alert-item-email-object"><translate>Subject</translate> <span class="required">*</span></label>
              <b-form-input
                id="alert-item-email-object"
                v-model="chatForm.Object"
                placeholder="Enter the message object"
                class="form-control font-size-h4"
              ></b-form-input>
            </div>
          </div>

          <div class="card-body align-items-center">
            <label class="font-weight-bold font-size-h6" for="alert-item-email-object"><translate>Message</translate> <span class="required">*</span></label>
            <div v-if="editor">
              <button
                class="btn btn-sm py-1 px-2 mr-1 mb-1 btn-outline-secondary"
                @click="editor.chain().focus().toggleBold().run()"
                :class="{ 'is-active': editor.isActive('bold') }"
                v-translate
              >
                Bold
              </button>
              <button
                class="btn btn-sm py-1 px-2 mr-1 mb-1 btn-outline-secondary"
                @click="editor.chain().focus().toggleItalic().run()"
                :class="{ 'is-active': editor.isActive('italic') }"
                v-translate
              >
                Italic
              </button>
            </div>
            <editor-content :editor="editor" class="border border-opalean-gray-light rounded-2 rounded p-4" />
          </div>

          <!--end::Body-->

          <!--begin::Footer-->
          <div class="card-footer align-items-center">
            <div class="d-flex align-items-center justify-content-between mt-5">
              <div class="mr-3">
                <a class="btn btn-clean btn-icon btn-md mr-1">
                  <i class="flaticon2-file icon-lg"></i>
                </a>
              </div>
              <div>
                <button
                  type="button"
                  class="btn btn-outline-secondary btn-md text-uppercase font-weight-bold chat-send py-2 px-6 mr-2"
                  @click="dismissModal()"
                  v-translate
                >
                  Cancel
                </button>
                <button
                  type="button"
                  :disabled="chatForm.Emails.length == 0"
                  class="btn btn-primary btn-md text-uppercase font-weight-bold chat-send py-2 px-6"
                  @click="sendData()"
                  v-translate
                >
                  Send
                </button>
              </div>
            </div>
          </div>
          <!--end::Footer-->
        </div>
        <!--end::Card-->
      </div>
    </div>
  </div>
  <!-- end::ChatModal -->
</template>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<script>
import Vue from "vue";
var vm = new Vue();

import { mapGetters } from "vuex";
import Multiselect from "vue-multiselect";
import { Editor, EditorContent } from "@tiptap/vue-2";
import StarterKit from "@tiptap/starter-kit"; //https://tiptap.dev/examples/default

import ApiService from "@/core/services/api.service";
//import Swal from "sweetalert2";

export default {
  name: "ChatModal",
  components: {
    Multiselect,
    EditorContent,
  },
  props: {
    chatModal: Object,
  },
  data() {
    return {
      // RTE
      editor: null,
      //
      chatForm: {
        Object: vm.$gettext("Request for return of pallets and handling supports"),
        Emails: [],
        Message: null,
      },
      //
      accountEmails: [],
    };
  },
  watch: {
    chatModal: {
      deep: true,
      immediate: false, // Initiate at first load, trigger the callback immediately with the current value of the expression
      handler: function () {
        console.log("chatModal::", this.chatModal);
        this.editor.commands.clearContent();
        this.editor.commands.setContent(
          `<p>${vm.$gettext("Hello")} ${this.chatModal.PartnerName},<br/><br/> 
        <strong>${vm.$gettext("As of today, you owe us :")}</strong></p>
        <ul>
          <li>${this.chatModal.PalletName} : <code>${this.chatModal.Quantity}</code></li>
        </ul>
        <p>${vm.$gettext("Thank you for doing what is necessary to proceed with their return as soon as possible.")}</p>
        <p>${vm.$gettext("Best regards,")}</br>
        ${this.chatModal.UserName}, <em>${this.chatModal.UserPartnerName}</em></p>
        <blockquote>
          ${vm.$gettext("This is an email generated by 👏")}
          <br />
          — NeoOpatrace
        </blockquote>`
        );
      },
    },
  },
  computed: {
    ...mapGetters(["getUserEmails", "getUserFullName"]),
  },
  mounted() {
    // Mount alert emails
    console.log(this.chatModal);
    this.chatForm.Emails = this.chatModal.AlertEMails;

    // Mount user emails
    this.accountEmails = this.getUserEmails ?? [];

    // Mount Editor
    this.editor = new Editor({
      content: `<p>${vm.$gettext("Hello")} ${this.chatModal.PartnerName},<br/><br/> 
        <strong>${vm.$gettext("As of today, you owe us :")}</strong></p>
        <ul>
          <li>${this.chatModal.PalletName} : <code>${this.chatModal.Quantity}</code></li>
        </ul>
        <p>${vm.$gettext("Thank you for doing what is necessary to proceed with their return as soon as possible.")}</p>
        <p>${vm.$gettext("Best regards,")}</br>
        ${this.chatModal.UserName}, <em>${this.chatModal.UserPartnerName}</em></p>
        <blockquote>
          ${vm.$gettext("This is an email generated by 👏")}
          <br />
          — NeoOpatrace
        </blockquote>`,
      extensions: [StarterKit],
      onCreate: () => {
        //this.$emit("input", this.editor.getHTML());
        this.chatForm.Message = this.editor.getHTML();
      },
      onUpdate: () => {
        // HTML
        //this.$emit("input", this.editor.getHTML());
        this.chatForm.Message = this.editor.getHTML();
        // JSON
        // this.$emit('input', this.chatModal.editor.getJSON())
      },
    });
  },
  beforeDestroy() {
    this.editor.destroy();
  },
  methods: {
    sendData() {
      const data = {
        SenderReplyEmail: this.getUserEmails[0], // Removed, then re-added
        SenderDisplayName: this.getUserFullName,
        ReceiversEmails: this.chatForm.Emails,
        Subject: this.chatForm.Object,
        Body: this.chatForm.Message,
        PartnerID: this.chatModal.PartnerID,
        PalletID: this.chatModal.PalletID,
        Direction: this.chatModal.Direction,
      };

      // Then store after a timeout
      setTimeout(() => {
        console.log("POST API:: ", data, this.chatForm);

        // Post AlertMessage
        (async () => {
          let response = null;
          let errorStatus = null;
          let errorData = null;
          try {
            console.log(data);
            response = await ApiService.post("/sendmessage", { AlertMessage: data });
          } catch (error) {
            // console.error("Error response:");
            // console.error(error); // ***
            // console.error(error.response.data); // ***
            // console.error(error.response.status); // ***
            // console.error(error.response.headers); // ***

            errorStatus = error.response.status;
            errorData = error.response.data.errors.join(", ");
          } finally {
            // console.log(typeof response);
            // console.log(typeof errorStatus);
            // console.log(typeof errorData);

            if (response === null && errorStatus !== null) {
              // Error
              setTimeout(() => {
                window.swalAlert.fire({
                  title: errorStatus,
                  html: "Damned, we are sorry, we have an error..." + (errorData !== null ? "<br/><code> " + errorData + " </code>" : ""),
                  icon: "error",
                  timer: 1200,
                  confirmButtonClass: "btn btn-secondary",
                  footer: '<a href="mailto:support@opalean.fr">' + vm.$gettext("Need to contact support ?") + "</a>",
                });
              }, 1200);
            } else if (response !== null && response.status === 200) {
              // Success
              window.swalToast.fire({
                title: "Alert message",
                html: "The message has been successfully <em>sended</em> !",
                icon: "success",
                //timer: 1200,
                //confirmButtonClass: "btn btn-secondary",
              });

              // Disnmiss modal
              this.dismissModal();
            }
          }
        })();
      }, 500);
    },
    dismissModal() {
      this.chatModal.show = false;
    },
    addEmail(newEmail) {
      this.accountEmails.push(newEmail);
      this.chatForm.Emails.push(newEmail);
    },
  },
};
</script>

<style lang="scss">
/* Basic editor styles */
.ProseMirror {
  > * + * {
    margin-top: 0.75em;
  }

  ul,
  ol {
    padding: 0 1rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.1;
  }

  code {
    background-color: rgba(#616161, 0.1);
    color: #616161;
  }

  pre {
    background: #0d0d0d;
    color: #fff;
    font-family: "JetBrainsMono", monospace;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;

    code {
      color: inherit;
      padding: 0;
      background: none;
      font-size: 0.8rem;
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }

  blockquote {
    padding-left: 1rem;
    border-left: 2px solid rgba(#0d0d0d, 0.1);
  }

  hr {
    border: none;
    border-top: 2px solid rgba(#0d0d0d, 0.1);
    margin: 2rem 0;
  }
}
</style>
