<template>
  <div class="card card-custom gutter-b card-stretch">
    <!--begin::Header-->
    <div class="card-header border-0 py-5 bg-opalean-white">
      <h3 class="card-title align-items-start flex-column my-1">
        <span class="card-label font-weight-bolder text-dark" v-translate> Activity </span>
        <span class="text-muted mt-2 font-weight-bold font-size-sm" v-translate> Operations </span>
      </h3>
      <div class="card-toolbar">
        <a
          class="btn btn-transparent btn-hover-info text-hover-white p-2 toggle"
          v-if="expandable"
          @click="
            parameters !== null && typeof parameters.Expanded !== 'undefined' && parameters.Expanded !== null
              ? (parameters.Expanded = !parameters.Expanded)
              : $set(parameters, 'Expanded', false)
          "
          ><span
            class="svg-icon m-0"
            :class="parameters !== null && typeof parameters.Expanded !== 'undefined' && parameters.Expanded ? 'svg-icon-info' : 'svg-icon-opalean-gray-medium'"
            ><inline-svg src="media/svg/icons/Layout/Layout-left-panel-1.svg"></inline-svg
          ></span>
        </a>
        <a class="btn btn-transparent btn-hover-info text-hover-white p-2 handle"
          ><span class="svg-icon svg-icon-opalean-gray-medium m-0"><inline-svg src="media/svg/icons/Layout/Layout-4-blocks.svg"></inline-svg></span>
        </a>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body">
      <b-skeleton-wrapper :loading="!ActivityWidget.Data">
        <template #loading>
          <div class="row">
            <b-col cols="4">
              <b-card no-body img-top>
                <b-skeleton-img animation="fade" card-img="top" aspect="4:3"></b-skeleton-img>
              </b-card>
            </b-col>
            <b-col cols="4">
              <b-card no-body img-top>
                <b-skeleton-img animation="fade" card-img="top" aspect="4:3"></b-skeleton-img>
              </b-card>
            </b-col>
            <b-col cols="4">
              <b-card no-body img-top>
                <b-skeleton-img animation="fade" card-img="top" aspect="4:3"></b-skeleton-img>
              </b-card>
            </b-col>
            <b-col cols="4">
              <b-card no-body img-top>
                <b-skeleton-img animation="fade" card-img="top" aspect="4:3"></b-skeleton-img>
              </b-card>
            </b-col>
            <b-col cols="4">
              <b-card no-body img-top>
                <b-skeleton-img animation="fade" card-img="top" aspect="4:3"></b-skeleton-img>
              </b-card>
            </b-col>
          </div>
        </template>
        <div class="row">
          <template v-for="(item, i) in ActivityWidget.Data">
            <div v-bind:key="i" :class="ActivityWidget.Data.length > 4 ? 'col-6 col-sm-4' : 'col-6 col-sm-6'">
              <!--begin::Tiles-->
              <div
                class="card card-custom gutter-b mb-0"
                style="height: 160px"
                v-bind:class="
                  getStatusType(item.Status, 'plain')
                    ? `bg-${getStatusType(item.Status, 'class')}`
                    : `bg-opalean-white bg-hover-${getStatusType(item.Status, 'class')} text-hover-light`
                "
              >
                <!-- v-bind:class="item.plain && `bg-${getStatusType(item.Status, 'class')}`" -->
                <div class="card-body d-flex flex-column justify-content-center hoverable">
                  <div class="text-hover-light">
                    <span
                      class="svg-icon svg-icon-3x ml-n2"
                      v-bind:class="getStatusType(item.Status, 'plain') ? 'svg-icon-white' : `svg-icon-${getStatusType(item.Status, 'class')}`"
                    >
                      <inline-svg v-bind:src="`${getStatusType(item.Status, 'icon')}`" />
                    </span>
                    <span
                      class="font-weight-bold --font-size-h1 display4 mt-3 text-primary text-hover-light align-middle"
                      v-bind:class="
                        getStatusType(item.Status, 'plain')
                          ? `text-inverse-${getStatusType(item.Status, 'class')}`
                          : `text-${getStatusType(item.Status, 'class')} text-hover-light`
                      "
                    >
                      <span v-if="item.Status !== 'A'"> {{ item.Value | getNumberFormat }} </span>
                      <span v-else> {{ item.Value }} </span>
                    </span>
                  </div>

                  <router-link
                    custom
                    class="stretched-link font-weight-bold font-size-lg mt-2 d-block"
                    :class="getStatusType(item.Status, 'plain') ? `text-inverse-${getStatusType(item.Status, 'class')}` : 'text-muted text-hover-light'"
                    :to="{ name: 'route.operations.list', params: { Status: item.Status, runCallBySearch: true } }"
                    v-if="item.Status !== 'A'"
                    >{{ $gettext(getStatusType(item.Status, "label")) }}
                  </router-link>
                  <div class="stretched-link font-weight-bold font-size-lg mt-2 text-muted text-hover-light" v-else v-on:click="jumpToAlerts">
                    {{ $gettext(getStatusType(item.Status, "label")) }}
                  </div>
                </div>
              </div>
              <!--end::Tiles-->
            </div>
          </template>
        </div>
      </b-skeleton-wrapper>
    </div>
    <!--end::Body-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import helpers from "@/core/statics/helpers.js";
import KTUtil from "@/assets/js/components/util";

export default {
  name: "ActivityWidget",
  props: {
    expandable: Boolean,
    parameters: Object,
  },
  data() {
    return {
      //https://michaelnthiessen.com/conditional-class-binding-vue/
    };
  },
  components: {},
  mounted() {
    console.log("ActivityWidget.vue");
  },
  mixins: [helpers],
  methods: {
    backgroundImage(path) {
      return process.env.BASE_URL + path;
    },
    jumpToAlerts() {
      console.log("jumpToAlerts");
      setTimeout(() => {
        KTUtil.scrollTo(KTUtil.getById("AlertsWidget"), -90, 500);
      }, 50);
    },
  },
  computed: {
    ...mapGetters(["getDashboardByWidgetName"]),
    ActivityWidget: function () {
      return this.getDashboardByWidgetName("ActivityWidget")[0]; // Make sure we return a flat array of objects
    },
  },
};
</script>
