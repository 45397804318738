<template>
  <div>
    <OperationListComponent
      :itemsLimit="4"
      :itemsPerPage="5"
      :itemsOrder="'ASC'"
      :showPagination="false"
      :showSearch="false"
      :showFilters="false"
      :showCreateButton="false"
      :showHeader="true"
    ></OperationListComponent>
  </div>
</template>

<script>
import OperationListComponent from "@/view/content/neoopatrace/components/OperationListComponent.vue";
import { mapActions } from "vuex";

export default {
  name: "last-operations-widget",
  components: { OperationListComponent },
  beforeMount() {
    this.updateFilteredOperations({
      IDs: null,
      maxDate: null,
      minDate: null,
      Filter: null,
      FilterOn: null,
      Selected: null,
      CurrentPage: null,
      PerPage: null,
    });
  },
  beforeDestroy() {
    this.updateFilteredOperations({
      IDs: null,
      maxDate: null,
      minDate: null,
      Filter: null,
      FilterOn: null,
      Selected: null,
      CurrentPage: null,
      PerPage: null,
    });
  },
  methods: {
    // Update filtered Operations to store IDs and filter when editing
    ...mapActions(["updateFilteredOperations"]),
  },
};
</script>
